export const USER_AGREEMENT_URL =
  "https://file.notion.so/f/f/482912e4-4344-464c-8701-238c0ef01ea7/1a6ffe11-089a-41a6-80f3-a48fb448ec42/1._Odin_Master_Platform_User_Agreement__Custody_Terms_06.10.2024.pdf?table=block&id=2b8d1275-f5db-470d-842c-a40bf1aa5b5d&spaceId=482912e4-4344-464c-8701-238c0ef01ea7&expirationTimestamp=1728396000000&signature=OhYZKFCtvYRPrWuFNQv7pIhtRwagMf987fjvzlGqyyM&downloadName=1.+Odin+Master+Platform+User+Agreement+%2B+Custody+Terms+06.10.2024.pdf";

export const SYND_TERMS_URL =
  "https://file.notion.so/f/f/482912e4-4344-464c-8701-238c0ef01ea7/f722c683-1868-4501-97c7-df8239873095/2._Odin_Master_Syndicate_Terms_07.10.2024.pdf?table=block&id=8d87f2da-431a-49c8-a4a8-385dec49387f&spaceId=482912e4-4344-464c-8701-238c0ef01ea7&expirationTimestamp=1728396000000&signature=9jylOC4H6Y10jp07WQrNU_BOr6Km1K0kpjA5Wnoo4qA&downloadName=2.+Odin+Master+Syndicate+Terms+07.10.2024.pdf";

export const SL_AGREEMENT_URL =
  "https://file.notion.so/f/f/482912e4-4344-464c-8701-238c0ef01ea7/641661db-6276-41ed-a1cc-efebbdfb08ac/3.Odin_Master_Syndicate_Lead_Agreement_07.10.2024.pdf?table=block&id=11821b33-eab9-80a8-9cf0-cb5f096d5164&spaceId=482912e4-4344-464c-8701-238c0ef01ea7&expirationTimestamp=1728388800000&signature=GUNEwD6od2iOAEz9QRlABDmLK_zDhmIXz2htid-D30g&downloadName=3.Odin_Master_Syndicate_Lead_Agreement_07.10.2024.pdf";

export type FormValues = {
  signature: string;
};
