import { css } from "@@panda/css";
import { BaseCurrency, PricePlanName } from "@internal/rest/generated/schemas";
import { SystemStyleObject } from "@pandacss/dev";
import { ReactNode } from "react";
import { EUR_FX_RATE, USD_FX_RATE } from "utility/consts";

export type Fee = {
  id:
    | "notarization"
    | "rollingClose"
    | "usInvestor"
    | "followOn"
    | "fx"
    | "sideletter";
  name: ReactNode;
  cost: ReactNode;
  info: ReactNode;
};

export const getFees = (pp: PricePlanName, cur: BaseCurrency): Fee[] => {
  switch (pp) {
    case "base_2023":
      return [
        getNotarizationFee(pp, cur),
        getRollingCloseFee(pp, cur),
        getSideletterFee(pp, cur),
        getUSInvestorFee(pp, cur),
        getFollowOnDealsFee(pp, cur),
      ];
    case "core_2023":
      return [
        getNotarizationFee(pp, cur),
        getRollingCloseFee(pp, cur),
        getSideletterFee(pp, cur),
        getUSInvestorFee(pp, cur),
        getFollowOnDealsFee(pp, cur),
      ];
    case "headliner_2023":
      return [
        getNotarizationFee(pp, cur),
        getRollingCloseFee(pp, cur),
        getSideletterFee(pp, cur),
        getUSInvestorFee(pp, cur),
        getFollowOnDealsFee(pp, cur),
      ];
    case "founder_2023_09":
      return [
        getNotarizationFee(pp, cur),
        getRollingCloseFee(pp, cur),
        getUSInvestorFee(pp, cur),
      ];

    default:
      return [];
  }
};

//

const secondaryColor: SystemStyleObject["color"] = "grey.gunsmoke";

const name = {
  primary: css({
    fontWeight: "bold",
    fontSize: "14px",
  }),
  secondary: css({
    color: secondaryColor,
  }),
};

const info = {
  primary: css({
    fontWeight: "normal",
    fontSize: "14px",
  }),
  secondary: css({
    color: secondaryColor,
  }),
};

const cost = {
  monetary: css({
    backgroundColor: "grey.minecraft",
    color: "white",
    width: "fit-content",
    borderRadius: "8px",
    p: "3px",
  }),
  box: css({
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
    gap: "8px",
    smDown: {
      flexDirection: "column",
    },
  }),
};

//

const withCurrency = (amount: number, cur: BaseCurrency): string => {
  let amt = amount;
  if (cur === "usd" || cur === "usdc") {
    amt = amount * USD_FX_RATE;
  }

  if (cur === "eur") {
    amt = amount * EUR_FX_RATE;
  }

  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: cur.toUpperCase(),
    currencyDisplay: "narrowSymbol",
  }).format(amt);
};

const getNotarizationFee = (tier: PricePlanName, cur: BaseCurrency): Fee => {
  const feeMap: Record<PricePlanName, null | number> = {
    branded_2022: null,
    branded_2023: null,
    core_2023_monthly: null,
    entry_2020: null,
    founder_2020: null,
    founder_2022: null,
    founder_2023: null,
    founder_2023_07: null,
    standard_2020: null,
    headliner_2023_monthly: null,
    standard_2022: null,
    standard_2023: null,

    // -- active plans | default currency is GBP
    founder_2023_09: 1000,

    base_2023: 1500,
    core_2023: 1000,
    headliner_2023: 1000,
  };
  return {
    id: "notarization",
    name: (
      <p className={name.primary}>
        Notarization of Legal Documents
        <span className={name.secondary}> (EU deals)</span>
      </p>
    ),
    cost: (
      <div className={cost.box}>
        <p className={cost.monetary}>
          {feeMap[tier] ? withCurrency(feeMap[tier], cur) : ""}
        </p>
      </div>
    ),
    info: (
      <p className={info.primary}>
        Required in some jurisdictions, and determined by the investee company’s
        country of incorporation.
      </p>
    ),
  };
};

const getRollingCloseFee = (tier: PricePlanName, cur: BaseCurrency): Fee => {
  const feeMap: Record<PricePlanName, null | number> = {
    branded_2022: null,
    branded_2023: null,
    core_2023_monthly: null,
    entry_2020: null,
    founder_2020: null,
    founder_2022: null,
    founder_2023: null,
    founder_2023_07: null,
    standard_2020: null,
    headliner_2023_monthly: null,
    standard_2022: null,
    standard_2023: null,

    // -- active plans | default currency is GBP
    founder_2023_09: 1000,

    base_2023: 1000,
    core_2023: 1000,
    headliner_2023: 1000,
  };

  const copyMap: Record<PricePlanName, null | string> = {
    branded_2022: null,
    branded_2023: null,
    core_2023_monthly: null,
    entry_2020: null,
    founder_2020: null,
    founder_2022: null,
    founder_2023: null,
    founder_2023_07: null,
    standard_2020: null,
    headliner_2023_monthly: null,
    standard_2022: null,
    standard_2023: null,

    founder_2023_09: "per additional close",

    base_2023: "plus % deal fee per additional close",
    core_2023: "plus % deal fee per additional close",
    headliner_2023: "plus % deal fee per additional close",
  };

  return {
    id: "rollingClose",
    name: <p className={name.primary}>Rolling Closes</p>,
    cost: (
      <div className={cost.box}>
        <p className={cost.monetary}>
          {" "}
          {feeMap[tier] ? withCurrency(feeMap[tier], cur) : ""}
        </p>
        <p>{copyMap[tier]}</p>
      </div>
    ),
    info: (
      <p className={info.primary}>
        Drawdown your investment in stages{" "}
        <span className={info.secondary}>
          (note: all closes must be on the same deal terms).
        </span>
      </p>
    ),
  };
};

const getUSInvestorFee = (tier: PricePlanName, cur: BaseCurrency): Fee => {
  const feeMap: Record<PricePlanName, null | number> = {
    branded_2022: null,
    branded_2023: null,
    core_2023_monthly: null,
    entry_2020: null,
    founder_2020: null,
    founder_2022: null,
    founder_2023: null,
    founder_2023_07: null,
    standard_2020: null,
    headliner_2023_monthly: null,
    standard_2022: null,
    standard_2023: null,

    // -- active plans | default currency is GBP
    founder_2023_09: 200,

    base_2023: 200,
    core_2023: 175,
    headliner_2023: 150,
  };

  return {
    id: "usInvestor",
    name: <p className={name.primary}>US Investors</p>,
    cost: (
      <div className={cost.box}>
        <p className={cost.monetary}>
          {feeMap[tier] ? withCurrency(feeMap[tier], cur) : ""}
        </p>
        <p>per US Investor</p>
      </div>
    ),
    info: (
      <p className={info.primary}>
        If an investor has US tax residence at the time of investment.
      </p>
    ),
  };
};

const getFollowOnDealsFee = (tier: PricePlanName, cur: BaseCurrency): Fee => {
  const feeMap: Record<PricePlanName, null | number> = {
    branded_2022: null,
    branded_2023: null,
    core_2023_monthly: null,
    entry_2020: null,
    founder_2020: null,
    founder_2022: null,
    founder_2023: null,
    founder_2023_07: null,
    standard_2020: null,
    headliner_2023_monthly: null,
    standard_2022: null,
    standard_2023: null,

    // -- active plans | default currency is GBP
    founder_2023_09: null,

    base_2023: 1200,
    core_2023: 950,
    headliner_2023: 700,
  };

  return {
    id: "followOn",
    name: (
      <p className={name.primary}>
        Follow on deals
        <span className={name.secondary}> (lower min. fee) </span>
      </p>
    ),
    cost: (
      <div className={cost.box}>
        <p className={cost.monetary}>
          {feeMap[tier] ? withCurrency(feeMap[tier], cur) : ""}
        </p>
        <p>min. fee</p>
      </div>
    ),
    info: (
      <p className={info.primary}>
        Reduced minimum fees for a subsequent deal into the same investee
        company.
      </p>
    ),
  };
};

const getSideletterFee = (_tier: PricePlanName, cur: BaseCurrency): Fee => {
  return {
    id: "sideletter",
    name: <p className={name.primary}>Side letters</p>,
    cost: (
      <div className={cost.box}>
        <p className={cost.monetary}>{withCurrency(1000, cur)}</p>
        <p>per side letter</p>
      </div>
    ),
    info: (
      <p className={info.primary}>
        If supplemental agreement(s) are required to confirm specific rights or
        additional privileges.
      </p>
    ),
  };
};
